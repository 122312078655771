import React, { useState } from "react";
import "./product.css";
import { Link } from "react-router-dom";
import productImage from "../../images/productImage1.png";
import { Helmet } from "react-helmet";

const Products = () => {
  const categories = [
    { id: "dry-fruits", name: "Ghee (5)" },
    { id: "mix-fruits", name: "Agarbatti (10)" },
    { id: "seeds", name: "Sweet & Biscuit (7)" },
    { id: "green", name: "Tablets" },
  ];

  const productsData = {
    "dry-fruits": [
      { id: 1, name: "Dry Fruit 1", price: 139, originalPrice: 199 },
      { id: 2, name: "Dry Fruit 2", price: 129, originalPrice: 179 },
    ],
    "mix-fruits": [
      { id: 3, name: "Mix Fruit 1", price: 149, originalPrice: 209 },
      { id: 4, name: "Mix Fruit 2", price: 139, originalPrice: 199 },
    ],
    seeds: [
      { id: 5, name: "Seed 1", price: 99, originalPrice: 149 },
      { id: 6, name: "Seed 2", price: 89, originalPrice: 129 },
    ],
    green: [
      { id: 7, name: "Green Product 1", price: 119, originalPrice: 169 },
      { id: 8, name: "Green Product 2", price: 109, originalPrice: 159 },
    ],
  };

  const [selectedCategory, setSelectedCategory] = useState("dry-fruits");
  const [products, setProducts] = useState(productsData[selectedCategory]);

  const handleCategoryChange = (categoryId) => {
    setSelectedCategory(categoryId);
    setProducts(productsData[categoryId]);
  };

  return (
    <>
    <Helmet>
        <title>Products - Medicinal Ghee, Agarbatthi, and More</title>
        <meta
          name="description"
          content="Explore our wide range of products including Ghee, Agarbatthi, and other medicinal products. Shop now for the best prices and quality."
        />
      </Helmet>
      <section className="minibreadCrumb">
        <div className="container">
          <div className="row align-items-center">
            <div className="col-md-6 col-6">
              <Link
                to="/product/product-details"
                className="back-icon text-decoration-none text-black d-flex align-items-center gap-2"
              >
                <i className="bi bi-arrow-left text-black"></i> Back to category
              </Link>
            </div>
            <div className="col-md-6 col-6">
              <div className="text-black d-flex justify-content-end gap-2">
                <Link className="text-black" to="/">
                  <i className="bi bi-house"></i>
                </Link>
                /
                <Link className="text-black" to="/product/product-details/cart">
                  Product URL
                </Link>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section className="productsPage">
        <div className="container">
          <h1>
            <b>Medicines</b>
          </h1>
          <div className="row">
            {/* Sidebar */}
            <div className="col-md-3 productSidebar">
              <h4>Categories</h4>
              <hr />
              <ul className="category-list">
                {categories.map((category) => (
                  <li
                    key={category.id}
                    className={
                      selectedCategory === category.id ? "active-category" : ""
                    }
                    onClick={() => handleCategoryChange(category.id)}
                  >
                    {category.name}
                  </li>
                ))}
              </ul>
            </div>

            {/* All Products */}
            <div className="col-md-9  all-products">
              <div className="row">
                {products.map((product) => (
                  <div key={product.id} className="col-md-4 col-6">
                    <div className="product-card-page">
                      <div className="product-image">
                        <img
                          src={productImage}
                          alt={product.name}
                          className="img-fluid"
                        />
                      </div>
                      <div className="productName">
                        <h3 className="product-title">{product.name}</h3>
                        <div className="price">
                          <span className="current-price">
                            ${product.price}.00
                          </span>
                          <br />
                          <span className="original-price">
                            ${product.originalPrice}.00
                          </span>
                        </div>
                      </div>
                      <label
                        htmlFor={`pot-${product.id}`}
                        className="pot-label"
                      >
                        *Pot:
                      </label>
                      <select id={`pot-${product.id}`} className="pot-select">
                        <option>--- Please Select ---</option>
                      </select>
                      <Link
                        to="/product/product-details"
                        className="add-to-cart"
                      >
                        Add to Cart
                      </Link>
                    </div>
                  </div>
                ))}
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default Products;
