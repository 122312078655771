import React, { useEffect, useState } from "react";
import "./checkout.css";
import check from "../../images/check.gif";
import { useNavigate } from "react-router-dom";
import { Helmet } from "react-helmet";
const Checkout = () => {
  useEffect(() => {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  }, []);
  const navigate = useNavigate();
  const [isPopupVisible, setIsPopupVisible] = useState(false);

  const handleConfirmOrder = (event) => {
    event.preventDefault();
    // Show popup when the form is submitted
    setIsPopupVisible(true);
  };

  const handleClosePopup = () => {
    setIsPopupVisible(false);
    navigate("/");
  };

  return (
    <>
      <Helmet>
        <title>Checkout - Secure Your Order Now</title>
        <meta
          name="description"
          content="Complete your checkout process securely and efficiently. Enter shipping details, review your order summary, and confirm your order."
        />
        <meta
          name="keywords"
          content="checkout, order summary, shipping details, secure payment, online store"
        />
      </Helmet>
      <section className="minibreadCrumb">
        <div className="container">
          <div className="row">
            <div className="col-md-6">
              <a
                to="/product/product-details"
                className="back-icon text-decoration-none text-black d-flex align-items-center gap-2"
              >
                <i className="bi bi-arrow-left text-black"></i> Back to category
              </a>
            </div>
            <div className="col-md-6">
              <div className="text-black d-flex justify-content-end gap-2">
                <a className="text-black" to="/">
                  <i className="bi bi-house"></i>
                </a>
                /
                <a className="text-black" to="/product/product-details/cart">
                  Shoping Cart
                </a>
                /
                <a className="text-black" to="#">
                  Checkout
                </a>
              </div>
            </div>
          </div>
        </div>
      </section>
      <div className="checkout">
        <div className="checkout-section container">
          <h1 className="mb-3">
            <b>Checkout</b>
          </h1>
          <div className="row">
            <div className="col-md-7">
              <div className="shipping-address">
                <h5>Shipping Address</h5>
                <hr />
                <form>
                  <div className="row">
                    <div className="col-md-6">
                      <div className="form-group">
                        <label htmlFor="first-name">First Name *</label>
                        <input
                          type="text"
                          id="first-name"
                          placeholder="First Name"
                          required
                        />
                      </div>
                    </div>
                    <div className="col-md-6">
                      <div className="form-group">
                        <label htmlFor="last-name">Last Name *</label>
                        <input
                          type="text"
                          id="last-name"
                          placeholder="Last Name"
                          required
                        />
                      </div>
                    </div>
                    <div className="col-md-6">
                      <div className="form-group">
                        <label htmlFor="company">Company</label>
                        <input type="text" id="company" placeholder="Company" />
                      </div>
                    </div>
                    <div className="col-md-6">
                      <div className="form-group">
                        <label htmlFor="address-1">Address 1 *</label>
                        <input
                          type="text"
                          id="address-1"
                          placeholder="Address 1"
                          required
                        />
                      </div>
                    </div>
                    <div className="col-md-6">
                      <div className="form-group">
                        <label htmlFor="address-2">Address 2</label>
                        <input
                          type="text"
                          id="address-2"
                          placeholder="Address 2"
                        />
                      </div>
                    </div>
                    <div className="col-md-6">
                      <div className="form-group">
                        <label htmlFor="city">City *</label>
                        <input
                          type="text"
                          id="city"
                          placeholder="City"
                          required
                        />
                      </div>
                    </div>
                    <div className="col-md-6">
                      <div className="form-group">
                        <label htmlFor="post-code">Post Code</label>
                        <input
                          type="text"
                          id="post-code"
                          placeholder="Post Code"
                        />
                      </div>
                    </div>
                    <div className="col-md-6">
                      <div className="form-group">
                        <label htmlFor="country">Country *</label>
                        <select id="country" required>
                          <option value="">-- Please Select --</option>
                          <option value="usa">USA</option>
                          <option value="canada">Canada</option>
                          {/* Add more options */}
                        </select>
                      </div>
                    </div>
                    <div className="col-md-6">
                      <div className="form-group">
                        <label htmlFor="region-state">Region / State *</label>
                        <select id="region-state" required>
                          <option value="">-- None --</option>
                          <option value="california">California</option>
                          <option value="texas">Texas</option>
                          {/* Add more options */}
                        </select>
                      </div>
                    </div>
                    <div className="col-md-12 mb-3">
                      <button type="submit" className="add-to-cart">
                        Continue
                      </button>
                    </div>
                  </div>
                </form>
              </div>
            </div>
            <div className="col-md-5">
              <div className="order-summary">
                <h5>Order Summary</h5>
                <hr />
                <form onSubmit={handleConfirmOrder}>
                  <div className="form-group">
                    <label htmlFor="shipping-method">Shipping Method</label>
                    <select id="shipping-method">
                      <option value="">-- Please Select --</option>
                      {/* Add more shipping options */}
                    </select>
                  </div>
                  <div className="form-group">
                    <label htmlFor="payment-method">Payment Method</label>
                    <select id="payment-method">
                      <option value="">-- Please Select --</option>
                      {/* Add more payment options */}
                    </select>
                  </div>
                  <div className="form-group">
                    <label htmlFor="comments">
                      Add Comments About Your Order
                    </label>
                    <textarea
                      id="comments"
                      placeholder="Your Comments"
                    ></textarea>
                  </div>
                  <div className="order-product">
                    <h4>Product Name</h4>
                    <p>1x Premium Afghani Seedless Black - Pot:: 100cm</p>
                    <p>Total: $101.00</p>
                  </div>
                  <div className="order-totals">
                    <table
                      className="table table-bordered"
                      style={{ borderColor: "var(--themeColor)" }}
                    >
                      <tbody>
                        <tr>
                          <td>Sub-Total</td>
                          <td>$101.00</td>
                        </tr>
                        <tr>
                          <td>Total</td>
                          <td>$101.00</td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                  <button type="submit" className="add-to-cart">
                    Confirm Order
                  </button>
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>

      {/* Success Popup */}
      {isPopupVisible && (
        <div className="popup-overlay">
          <div className="popup-content">
            <img src={check} alt="" />
            <h2>Your order has been placed!</h2>
            <p>Your order has been successfully processed!</p>
            <p>
              Please direct any questions you have to the{" "}
              <a style={{ color: "var(--themeColor)" }} to="/contact-us">
                {" "}
                store owner.
              </a>
            </p>
            <button onClick={handleClosePopup} className="add-to-cart">
              Close
            </button>
          </div>
        </div>
      )}
    </>
  );
};

export default Checkout;
