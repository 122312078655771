import React, { useEffect, useState } from "react";
import "./cart.css";
import image from "../../images/footer1.jpg";
import { Link, useNavigate } from "react-router-dom";
import { Helmet } from "react-helmet";

const Cart = () => {
  useEffect(() => {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  }, []);
  const navigate = useNavigate();
  const [quantity, setQuantity] = useState(1);
  const unitPrice = 101.0;

  // Function to update the quantity
  const updateQuantity = (operation) => {
    if (operation === "increment") {
      setQuantity((prevQuantity) => prevQuantity + 1);
    } else if (operation === "decrement" && quantity > 1) {
      setQuantity((prevQuantity) => prevQuantity - 1);
    }
  };

  // Calculate total price and subtotal
  const totalPrice = (unitPrice * quantity).toFixed(2);

  return (
    <>
      <Helmet>
        <title>Shopping Cart - Your Items</title>
        <meta
          name="description"
          content="View and manage items in your shopping cart."
        />
        <meta
          name="keywords"
          content="shopping cart, e-commerce, checkout, products"
        />
      </Helmet>
      <section className="minibreadCrumb">
        <div className="container">
          <div className="row">
            <div className="col-md-6">
              <Link
                to="/product/product-details"
                className="back-icon text-decoration-none text-black d-flex align-items-center gap-2"
              >
                <i className="bi bi-arrow-left text-black"></i> Back to category
              </Link>
            </div>
            <div className="col-md-6">
              <div className="text-black d-flex justify-content-end gap-2">
                <Link className="text-black" to="/">
                  <i className="bi bi-house"></i>
                </Link>
                /
                <Link className="text-black" to="#">
                  Shoping Cart
                </Link>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section className="cart">
        <div className="container">
          <h2>Shopping Cart</h2>

          <div className="table-responsive">
            <table
              className="table table-bordered"
              style={{ borderColor: "var(--themeColor)" }}
            >
              <thead>
                <tr>
                  <th>Image</th>
                  <th>Product Name</th>
                  <th>Model</th>
                  <th>Quantity</th>
                  <th>Unit Price</th>
                  <th>Total</th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td className="text-center">
                    <img src={image} alt="Product Image" width="100" />
                  </td>
                  <td>
                    Pot: 100cm <br />
                    Reward Points: 200
                  </td>
                  <td>Product 3</td>
                  <td>
                    <button
                      className="btn btn-outline-primary btn-sm mx-1"
                      onClick={() => updateQuantity("decrement")}
                    >
                      -
                    </button>
                    <span className="mx-2">{quantity}</span>
                    <button
                      className="btn btn-outline-primary btn-sm mx-1"
                      onClick={() => updateQuantity("increment")}
                    >
                      +
                    </button>
                  </td>
                  <td>${unitPrice}</td>
                  <td>${totalPrice}</td>
                </tr>
                <tr>
                  <td colSpan="5" className="text-end">
                    <strong>Sub-Total</strong>
                  </td>
                  <td>${totalPrice}</td>
                </tr>
                <tr>
                  <td colSpan="5" className="text-end">
                    <strong>Total</strong>
                  </td>
                  <td>
                    <strong>$ {totalPrice}</strong>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
          <div className="d-flex justify-content-between">
            <button onClick={() => navigate("/")} className="add-to-cart">
              Continue to Shoping
            </button>
            <button
              onClick={() => navigate("/product/product-details/cart/checkout")}
              className="add-to-cart"
            >
              Checkout
            </button>
          </div>
        </div>
      </section>
    </>
  );
};

export default Cart;
