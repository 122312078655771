import React, { useState } from "react";
import "./product-tabs.css";
import Slider from "react-slick";
import productImage from "../../images/productImage1.png";
import productImage2 from "../../images/chayanprash.png";
import productImage3 from "../../images/dhoop.png";
import productImage4 from "../../images/shampoo.png";
import { Link, useNavigate } from "react-router-dom";

const ProductsTabs = () => {
  const navigate = useNavigate();
  const settings = {
    dots: true,
    infinite: false,
    speed: 500,
    slidesToShow: 4,
    slidesToScroll: 4,
    initialSlide: 0,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 3,
          infinite: true,
          dots: true,
        },
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 2,
          dots: false,
        },
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
          dots: false,
        },
      },
    ],
  };

  const [activeTab, setActiveTab] = useState("tab1");

  const products = {
    tab1: [
      {
        name: "Cow Ghee - 1 Leter",
        description: "Description Cow Ghee",
        price: "1200",
        Image: productImage,
      },
      {
        name: "Chayanprash - 1 Leter",
        description: "Description Chayanprash",
        price: "1500",
        Image: productImage2,
      },
      {
        name: "Dhoop",
        description: "Description Dhoop",
        price: "200",
        Image: productImage3,
      },
      {
        name: "Shampoo & Oil",
        description: "Description Shampoo & Oil",
        price: "500",
        Image: productImage4,
      },
    ],
    tab2: [
      {
        name: "Sweets & Biscuits",
        description: "Description Sweets & Biscuits",
        price: "800",
        Image: productImage3,
      },
      {
        name: "Tea",
        description: "Description Tea",
        price: "300",
        Image: productImage2,
      },
      {
        name: "Rice",
        description: "Description Rice",
        price: "400",
        Image: productImage,
      },
      {
        name: "Flour",
        description: "Description Flour",
        price: "100",
        Image: productImage4,
      },
    ],
  };

  return (
    <>
      <section className="products-tabs mt-3">
        <div className="container">
          {/* Tab Buttons */}
          <div className="product_heading_tab">
            <div>
              <h2>
                <b>Pure Ghee & More</b> <br />
                Bringing Natural Goodness to Your Home!
              </h2>
            </div>
            <div className="tabs">
              <button
                className={`tab-button ${activeTab === "tab1" ? "active" : ""}`}
                onClick={() => setActiveTab("tab1")}
              >
                Cow Ghee
              </button>
              <button
                className={`tab-button ${activeTab === "tab2" ? "active" : ""}`}
                onClick={() => setActiveTab("tab2")}
              >
                Chawanprash
              </button>
            </div>
          </div>

          {/* Tab Content */}
          <div className="tab-content mt-3">
            <div className="slider-container">
              <Slider {...settings}>
                {products[activeTab]?.map((product, index) => (
                  <div key={index}>
                    <div className="product-card">
                      <Link to={"/product/product-details"}>
                        <div className="product-image">
                          <img
                            src={product.Image}
                            alt={`Product ${index + 1}`}
                          />
                        </div>
                        <div className="productName">
                          <h3 className="product-title">{product.name}</h3>
                          <div className="price">
                            <span className="current-price">
                              ₹ {product.price}
                            </span>
                          </div>
                        </div>
                      </Link>
                      <label htmlFor={`pot-${index}`} className="pot-label">
                        *Pot:
                      </label>
                      <select id={`pot-${index}`} className="pot-select">
                        <option>--- Please Select ---</option>
                        <option>1</option>
                        <option>1</option>
                      </select>
                      <button
                        onClick={() => navigate("/product/product-details")}
                        className="add-to-cart"
                      >
                        Add to Cart
                      </button>
                    </div>
                  </div>
                ))}
              </Slider>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default ProductsTabs;
