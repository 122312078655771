import React, { useEffect, useState } from "react";
import "./productdetails.css";
import ReactImageMagnify from "react-image-magnify";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css"; // Import slick styles
import image1 from "../../images/articleimg1.jpg";
import image2 from "../../images/articleimg2.jpg";
import image3 from "../../images/articleimg3.jpg";
import image4 from "../../images/articleimg4.jpg";
import Testimonial from "../../Components/Testimonial/Testimonial";
import ProductsTabs from "../../Components/ProductsTabs/ProductsTabs";
import SubscribeForm from "../../Components/SubscribeForm/SubscribeForm";
import { Link, useNavigate } from "react-router-dom";
import { Helmet } from "react-helmet";


const ProductDetails = () => {
  useEffect(()=>{
    window.scrollTo({
      top:0,
      behavior:'smooth'
    })
  },[])
  const navigate = useNavigate();
  const [quantity, setQuantity] = useState(0);
  const [currentImage, setCurrentImage] = useState(image1); // For tracking the selected image

  const increaseQuantity = () => setQuantity((prev) => prev + 1);
  const decreaseQuantity = () =>
    setQuantity((prev) => (prev > 0 ? prev - 1 : 0));

  const images = [image1, image2, image3, image4];

  return (
    <>
     <Helmet>
        <title>Desi Cow Ghee - Buy Pure and Natural Ghee Online</title>
        <meta name="description" content="Buy pure and natural Desi Cow Ghee from Vedhlakshna at ₹399. Available in various quantities. Free shipping on all orders!" />
        <meta name="keywords" content="Desi Cow Ghee, Pure Ghee, Vedhlakshna, Natural Ghee, Organic Ghee, Buy Ghee Online" />
        <meta property="og:title" content="Desi Cow Ghee - Buy Pure and Natural Ghee Online" />
        <meta property="og:description" content="Buy pure and natural Desi Cow Ghee from Vedhlakshna at ₹399. Available in various quantities. Free shipping on all orders!" />
        <meta property="og:image" content={image1} />
        <meta property="og:url" content="https://panchgavyamrit.com/all-products" />
      </Helmet>

      <section className="minibreadCrumb">
        <div className="container">
          <div className="row align-items-center">
            <div className="col-md-6 col-6">
              <Link
                to="/"
                className="back-icon text-decoration-none text-black d-flex align-items-center gap-2"
              >
                <i className="bi bi-arrow-left text-black"></i> Back to category
              </Link>
            </div>
            <div className="col-md-6 col-6">
              <div className="text-black d-flex justify-content-end gap-2">
                <Link className="text-black" to="/">
                  <i className="bi bi-house"></i>
                </Link>
                /
                <Link className="text-black" to="#">
                  Product URL
                </Link>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section className="product-details">
        <div className="container">
          <div className="row">
            <div className="col-md-6 order-2 order-md-1">
              <div className="review">
                <i className="bi bi-star"></i>
                <i className="bi bi-star"></i>
                <i className="bi bi-star"></i>
                <i className="bi bi-star"></i>
                <i className="bi bi-star"></i>
              </div>
              <div className="product-details-content">
                <h5>
                <Link to="#">Desi Cow Ghee</Link>
                </h5>
                <h1>गाय का देसी घी</h1>
                <p>
                  <b>Product Details:</b>
                </p>
                <ul>
                  <li>
                    <b>Brand:</b> Vedhlakshna
                  </li>
                  <li>
                    <b>Availability:</b> Available
                  </li>
                  <li>
                    <div>
                      <b>Quantity:</b>
                      <span className="quantity-buttons">
                        <button onClick={decreaseQuantity}>-</button>
                        <span style={{ margin: "0 10px" }}>{quantity}</span>
                        <button onClick={increaseQuantity}>+</button>
                      </span>
                    </div>
                  </li>
                  <li className="price">
                    <b>Price:</b>
                    <span className="fs-2">₹399</span>
                    &nbsp;
                  </li>
                  <li>
                    <button
                    className="add-to-cart"
                      onClick={() => navigate("/product/product-details/cart")}
                    >
                      Add To Cart <i className="bi bi-cart"></i>
                    </button>
                  </li>
                </ul>
              </div>
            </div>

            <div className="col-md-6 order-1 order-md-2">
              <div className="slider-container">
                <ReactImageMagnify
                  {...{
                    smallImage: {
                      alt: "Product Image",
                      isFluidWidth: true,
                      src: currentImage,
                    },
                    largeImage: {
                      src: currentImage,
                      width: 1200,
                      height: 1800,
                    },
                    enlargedImagePosition: "over", // Ensures zoom appears over the image
                  }}
                />
                <div className="thumbnail-container">
                  {images.map((img, index) => (
                    <div
                      key={index}
                      className="thumbnail-wrapper"
                      onClick={() => setCurrentImage(img)}
                    >
                      <img src={img} alt={`Thumbnail ${index + 1}`} />
                    </div>
                  ))}
                </div>
              </div>
            </div>
          </div>

          <div className="descriptionsTabs">
            <div className="container">
              <div id="exTab1" className="container">
                <ul className="nav nav-pills">
                  <li className="nav-item">
                    <Link
                      className="product-details-description-button active"
                      id="tab1"
                      data-bs-toggle="tab"
                      to="#1a"
                    >
                      Description
                    </Link>
                  </li>
                  <li className="nav-item">
                    <Link
                      className="product-details-description-button"
                      id="tab2"
                      data-bs-toggle="tab"
                      to="#2a"
                    >
                      Review
                    </Link>
                  </li>
                </ul>

                <div className="tab-content mt-3">
                  <div className="tab-pane active" id="1a">
                    <p>
                      Lorem ipsum dolor sit amet, consectetur adipisicing elit.
                      A suscipit amet natus quod doloribus possimus, fugiat iure
                      tempora repellendus deserunt sit ratione sequi sint
                      necessitatibus debitis temporibus corporis incidunt
                      tempore.
                    </p>
                  </div>
                  <div className="tab-pane" id="2a">
                    <p>
                      Lorem ipsum dolor sit amet, consectetur adipisicing elit.
                      Quam cumque, officia vel velit molestias alias dolorum
                      ipsa accusantium delectus eligendi corrupti praesentium
                      aut in officiis maxime optio, iste pariatur inventore?
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

      <section className="testimonial">
        <Testimonial />
      </section>

      <section className="productstabs">
        <ProductsTabs />
      </section>

      <section className="sbsForm">
        <SubscribeForm />
      </section>
    </>
  );
};

export default ProductDetails;
